import { useState } from "react";
import { useAppDispatch } from "../services/states/store";
import actions from "../services/states/actions";
import { useNavigate } from "react-router";
import { Icon } from "@iconify/react";
import toast from "react-hot-toast";

export const SessionCard = (props: {
  lefticon: string;
  title: string;
  desc: string;
  righticon: string;
  righttext: string;
  personaSetting?:
    | {
        persona_id: string;
        language: string;
        accent: string;
        gender: string;
      }
    | undefined;
}) => {
  const [focused, setFocused] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <div
      onMouseOver={props.righttext=="Completed"?()=>{}: () => setFocused(true)}
      onMouseOut={() => setFocused(false)}
      onClick={() => {
        if(props.righttext=="Completed"){
          toast.error("Completed personas cannot be attempted")
          return;
        }
        if (props.personaSetting) {
          navigate("/training/conversations");
          localStorage.setItem("persona", props.title);
          localStorage.setItem("persona_desc", props.desc);
          localStorage.setItem(
            "p_settings",
            JSON.stringify(props.personaSetting)
          );
          dispatch(actions.selectPersona(props.title));
        } else {
          toast.error(
            "Persona is not setup completely. Please ask your trainer to complete the setup"
          );
        }
      }}
      className={`flex items-start gap-2.5 md:gap-4 ${
        focused
          ? "bg-bgsecondary border-bgprimary"
          : "bg-white border-transparent"
      } ${
        props.righttext === "Completed" ? "order-4" : "order-1 cursor-pointer"
      } rounded-md p-3 w-full md:p-5 border-l-8 shadow-cardshadow`}
    >
      <img
        className="object-center w-[3.5rem] md:w-[4.5rem] mt-2"
        src={props.lefticon}
        alt=""
      />
      <div className="flex-1 md:flex-[2]">
        <h3 className="font-bold md:text-lg text-gray-800">
          Persona: {props.title}
        </h3>
        <p className="text-xs md:text-sm w-full text-gray-500 my-2">
          {props.desc?.length > 0 ? props.desc : "No description found."}
        </p>
      </div>
      <div className="text-gray-900 max-md:hidden flex-[1] flex flex-col items-center justify-center self-stretch">
        <Icon className="text-4xl md:text-6xl" icon={props.righticon} />
        <p className="text-sm md:text-xl font-semibold">{props.righttext}</p>
      </div>
      <Icon
        icon="fluent-mdl2:chevron-right-med"
        className="self-center md:text-2xl"
      />
    </div>
  );
};
