import { Icon } from "@iconify/react";
import DownloadIcon from "../assets/download.png";
import moment from "moment";
import { transport } from "../services/Network";
import { CircularProgress } from "@mui/material";
import { useState } from "react";
import toast from "react-hot-toast";
interface Props {
  opened: boolean;
  setOpen: () => void;
  [key: string]: any;
}
const FeedbackCard = ({ opened, setOpen, ...props }: Props) => {
  const [updating, setUpdating] = useState(false);
  return (
    <div>
      <div
        className={`flex items-center py-2 md:py-4 md:pr-9 pl-1 md:pl-7 overflow-y-auto whitespace-nowrap gap-1 md:gap-3 border-l-[6px] md:border-l-8 rounded-md text-xs md:text-sm font-semibold ${
          opened
            ? "border-bgprimary bg-[#C3EAF9]"
            : "border-[#F5F5F5] bg-[#F5F5F5]"
        }`}
      >
        <div>Session #{props.num}</div>
        <div
          className={`border-x-2 px-1 md:px-3 ${
            opened ? "border-black leading-3" : "border-transparent"
          }`}
        >
          {props.persona_id}
        </div>
        <div>
          {moment.utc(props.created_at).local().format("Do MMMM YYYY, h:mma")}
        </div>
        {opened && (
          <div
            className={`border-l-2 px-1 md:px-3 ${
              opened ? "border-black leading-3" : "border-transparent"
            }`}
          >
            {props.conversation_id}
          </div>
        )}
        <div className="flex-1 min-w-5"></div>
        <div className="pr-3">
          <span
            className={
              "capitalize pr-3 " +
              (props.status == "FAIL" ? "text-[#E40000]" : "text-[#00871B]")
            }
          >
            {props.status}
          </span>
          Overall Score - {props.score}
        </div>
        <Icon
          onClick={setOpen}
          icon={opened ? "nimbus:chevron-up" : "nimbus:chevron-down"}
          className="text-3xl leading-3 cursor-pointer"
        />
      </div>
      {opened && (
        <div className="px-3 md:px-6 pt-2 pb-3 md:pt-3 md:pb-6 bg-[#F5F5F5] rounded-b-md flex flex-col gap-4">
          {props.assessment?.length > 0 ? (
            props.assessment.map((item: any, idx: number) => (
              <div
                key={"Feedbacksubcard-" + idx}
                className="flex gap-2 max-md:flex-col md:gap-4 md:justify-between bg-white border border-gray-200 rounded-md pt-2.5 md:pt-4 pb-4 md:pb-6 px-3"
              >
                <div className="w-full md:max-w-[80%] md:flex-1">
                  <h3 className="text-primary font-bold pb-1 capitalize">
                    {item.assess}
                  </h3>
                  <div className="font-light text-sm max-xl:line-clamp-3">
                    {item.assess_eval}
                  </div>
                </div>
                <div className="md:px-6 md:w-32 max-md:flex gap-3 items-end">
                  <div
                    className={`text-xl md:text-3xl font-extrabold ${
                      item.assess_result === "FAIL"
                        ? "text-[#E40000]"
                        : "text-[#00871B]"
                    }`}
                  >
                    {item.assess_score}
                  </div>
                  <div className="font-semibold capitalize md:text-xl">
                    {item.assess_result}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center text-gray-500 italic p-8 h-full">
              No assesment generated for the session
            </div>
          )}
          <div className="flex items-center gap-6">
            <button
              onClick={() => props.downloadData()}
              className="flex gap-2 md:gap-4 text-white bg-primary max-w-fit py-2 pl-4 pr-8 md:py-5 font-medium md:text-[15px] items-center rounded-md"
            >
              <img
                src={DownloadIcon}
                className="invert max-md:w-8"
                alt="download"
              />
              <p className="text-xs">Download Full Conversation</p>
            </button>
            <button
              onClick={async () => {
                try {
                  setUpdating(true);
                  const resp = await transport("/scores/refresh_score", {
                    method: "POST",
                    data: {
                      conversation_id: props.conversation_id,
                      id: props.id,
                    },
                  });
                  if (resp.status === 200) {
                    toast.success(resp.data.message);
                    props.onRefresh();
                  } else {
                    toast.error(resp.data?.message ?? "Something went wrong");
                  }
                } finally {
                  setUpdating(false);
                }
              }}
              className="flex gap-2 md:gap-4 text-white bg-primary max-w-fit py-2 pl-4 pr-8 md:py-5 font-medium md:text-[15px] items-center rounded-md"
            >
              <Icon icon="tabler:refresh" className="text-[27px] text-white" />
              <p className="text-xs">Refresh</p>
            </button>
          </div>
        </div>
      )}
      {updating ? (
        <div className="fixed top-0 z-50 left-0 w-screen h-screen bg-black bg-opacity-75 flex items-center justify-center">
          <CircularProgress size="60px" />
          <div className="text-white font-semibold text-xl p-6">
            Refreshing your conversation
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default FeedbackCard;