export type CustomSpeechRecognitionEvent = {
    results: {
        isFinal: boolean;
        [index: number]: {
            transcript: string;
            confidence: number;
        };
    }[];
};

export type CustomSpeechRecognitionErrorEvent = {
    error: string;
};

export type TranscriptionFunction = (audioBlob: Blob, lang: string, id: string) => Promise<string>;

class CustomSpeechRecognition {
    lang: string;
    interimResults: boolean;
    id: string;
    recognizing: boolean;
    onstart: (() => void) | null;
    onresult: ((event: CustomSpeechRecognitionEvent) => void) | null;
    onend: (() => void) | null;
    onerror: ((event: CustomSpeechRecognitionErrorEvent) => void) | null;

    private mediaRecorder: MediaRecorder | null;
    private audioChunks: Blob[];
    private localStream: MediaStream | null;
    private apiTranscriptionFunction: TranscriptionFunction;

    constructor(apiTranscriptionFunction: TranscriptionFunction) {
        this.apiTranscriptionFunction = apiTranscriptionFunction;
        this.lang = 'en';
        this.id = "";
        this.interimResults = false;
        this.recognizing = false;

        this.onstart = null;
        this.onresult = null;
        this.onend = null;
        this.onerror = null;

        this.mediaRecorder = null;
        this.audioChunks = [];
        this.localStream = null;
    }

    async start(): Promise<void> {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.localStream = stream;
            this.mediaRecorder = new MediaRecorder(stream);

            this.mediaRecorder.addEventListener('dataavailable', (event) => {
                this.audioChunks.push(event.data);
            });

            this.mediaRecorder.start(1000);
            this.recognizing = true;

            if (this.onstart) this.onstart();
        } catch (error:any) {
            if (this.onerror) this.onerror({ error: error.message || 'Error accessing microphone' });
        }
    }

    stop(): void {
        if (!this.mediaRecorder) return;

        this.mediaRecorder.stop();
        this.mediaRecorder.onstop = async () => {
            try {
                const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
                this.audioChunks = [];

                const transcript = await this.apiTranscriptionFunction(audioBlob, this.lang, this.id);

                if (this.onresult) {
                    const event: CustomSpeechRecognitionEvent = {
                        results: [
                            {
                                isFinal: true,
                                0: {
                                    transcript: transcript,
                                    confidence: 1.0,
                                },
                            },
                        ],
                    };
                    this.onresult(event);
                }
            } catch (error:any) {
                if (this.onerror) this.onerror({ error: error.message || 'Error during transcription' });
            } finally {
                if (this.localStream) {
                    this.localStream.getTracks().forEach((track) => track.stop());
                }
                this.localStream = null;
                this.recognizing = false;

                if (this.onend) this.onend();
            }
        };
    }
}
export default CustomSpeechRecognition;